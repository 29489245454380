@import url("https://fonts.googleapis.com/css2?family=family=Quicksand:wght@300;600;700&display=swap");

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-size: 5rem;
  margin-bottom: 0;
  font-weight: 600;
  text-align: center;
}

h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.5rem;
  font-weight: 600;
}

.notificationText {
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: 30px;
  text-align: center;
}

.inputContainer {
  display: flex;
  width: 350px;
  flex-direction: column;
  justify-content: space-between;
  height: 120px;
}

.inputContainer input {
  border-radius: 8px;
  border: 1px solid #000;
  background: #fff;
  height: 44px;
  font-size: 1.1rem;
  padding-left: 0.6rem;
}

.inputContainer button {
  background-color: #cb6594;
  color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  height: 44px;
  border-style: none;
  font-size: 1.1rem;
}

.inputContainer button:hover {
  background-color: #bf407b;
}

.fairyLogo img {
  height: 400px;
  width: 400px;
}

.success {
  color: green;
}

.error {
  color: red;
}

@media (min-width: 1200px) {
  h1 {
    margin-top: 150px;
    font-size: 7.5rem;
  }

  h2 {
    font-size: 5rem;
  }

  .inputContainer {
    width: 472px;
  }

  .fairyLogo img {
    height: 500px;
    width: 500px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    border: 0;
  }

  .notificationText {
    margin-bottom: 90px;
  }
}
